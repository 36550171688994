import * as MT from "./mutationTypes";

export default {
  [MT.SET_SNACKBAR]: (state, data) => (state.snackbar = { ...data, status: true }),
  [MT.APPEND_TO_FORM]: (state, payload) => (state.formData = { ...state.formData, ...payload }),
  [MT.RESET_FORM]: (state) => (state.formData = {}),
  [MT.SAVE_PIXELS]: (state, pixel) => (state.tyPixels = { ...state.tyPixels, ...pixel }),
  [MT.SET_USER_PROFILE]: (state, userData) => (state.userProfile = userData),
  [MT.SET_COUPON_DATA]: (state, couponData) => (state.couponData = couponData),
  [MT.SET_OTP_REQUEST_ID]: (state, requestId) => {
    state.otpRequestId = requestId;
  }
};
