<template class="pa-0 ma-0">
  <div>
    <v-card class="quiz-box ma-2" style="z-index: 2">
      <v-card-item class="text-center pa-0 ma-0">
        <p class="font-weight-bold ty-text">Thank you for Participating</p>
        <p class="ma-1 loading-text" v-if="!isCouponsLoading">Getting Best coupons for you .....</p>
        <div class="ma-3 text-start" v-if="couponData.length > 3">
          <p class="ma-1 loading-text">You can now redeem the below coupons</p>
          <v-row>
            <v-col
              v-for="(item, index) in couponData"
              :key="index"
              :cols="couponData.length < 5 ? 6 : 4"
              class="d-flex justify-center"
            >
              <v-card
                :class="couponData.length < 5 ? 'copoun' : 'small-cards copoun'"
                @click="openOfferDetail(item)"
                :style="{
                  opacity: item.isRedeemed ? 0.5 : 1
                }"
              >
                <div>
                  <div class="img-wrapper d-flex justify-center align-center">
                    <v-img class="align-end offer-img" :src="item.display_image_url" cover> </v-img>
                  </div>

                  <div class="offer-heading">
                    {{ item.title }}
                  </div>
                  <div class="pl-1 description">
                    <p>{{ item.description }}</p>
                  </div>
                  <div class="redeem-btn text-center mt-1">
                    <a @click="openOfferDetail(item)">Get Offer</a>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="" v-if="vendor == 'grabon'">
            <v-col cols="12" class="powered-by">
              <span>Powered By<v-img height="25" src="@/assets/grabon.png"></v-img></span>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <p class="ma-1 loading-text" v-if="isCouponsLoading">No Coupons available at the moment</p>
        </div>
      </v-card-item>
    </v-card>
    <div class="bottom-card-wrapper" v-if="showCouponDetails && selectedItem">
      <v-card class="bottom-card" ref="card">
        <v-row class="mt-1">
          <v-col cols="10" class="pa-4 d-flex justify-center align-center" style="height: 75px">
            <v-card :style="adjestLogo()">
              <v-img :src="selectedItem.display_image_url" cover> </v-img>
              <v-card-title class="text-h6"> </v-card-title>
            </v-card>
          </v-col>
          <v-col cols="2" class="pb-0 pr-4 pb-3 pt-1 d-flex justify-center align-center pl-0">
            <div class="text-end d-flex justify-center align-center" @click="handleClose">
              <span class="mdi mdi-close text-h4"></span>
            </div>
          </v-col>
        </v-row>

        <div class="title_wraper">
          <p class="title">{{ selectedItem.title }}</p>
          <p class="pr-3 validity-text" v-if="vendor == 'jio'">
            {{ formatDateWithMoment(selectedItem.expiry_date) }}
          </p>
        </div>

        <p class="text-body-2 pa-3">
          {{ truncatedText(selectedItem.description) }}
          <a class="text-body-2" href="#" @click.prevent="toggleShowMore" v-if="vendor == 'grabon'">
            {{ isExpanded ? "View less" : "Show more" }}
          </a>
        </p>

        <div v-if="selectedItem.offer_code && vendor == 'grabon'" class="code-wrapper">
          <span class="code">{{ selectedItem.offer_code }}</span>
          <v-btn class="font-weight-bold" @click="copyText(selectedItem)"
            ><v-icon>mdi mdi-content-copy</v-icon>COPY CODE</v-btn
          >
        </div>
        <div v-if="selectedItem.offer_code && vendor == 'jio'" class="code-wrapper">
          <p v-if="selectedItem.offer_code" class="font-weight-bold your-code">Your Coupon code :</p>
          <span style="filter: blur(3px)" class="code">{{ cropCode(selectedItem.offer_code) }}</span>
        </div>
        <div class="pl-5 pr-5 pt-1 how-to-wrapper">
          <p v-if="vendor == 'grabon'">How to Redeem?</p>
          <div v-if="redemptionSteps != ''" v-html="redemptionSteps" class="steps-container"></div>
        </div>
        <v-dialog max-width="500" transition="dialog-bottom-transition">
          <template v-slot:activator="{ props: activatorProps }">
            <v-btn
              v-if="selectedItem.tnc"
              size="x-small"
              v-bind="activatorProps"
              text="Terms & Conditions"
              variant="text"
              color="#4676ff"
            ></v-btn>
          </template>

          <template v-slot:default="{ isActive }">
            <v-card>
              <p class="tnc-text">Terms & Conditions</p>
              <v-skeleton-loader v-if="!isIFrameLoaded" type="paragraph"></v-skeleton-loader>
              <iframe :src="selectedItem.tnc" @load="onIframeLoad" scrolling="yes"> </iframe>

              <div class="tnc-close-btn">
                <v-btn variant="outlined" class="visit-btn mt-3" text="Got it" @click="isActive.value = false"></v-btn>
              </div>
            </v-card>
          </template>
        </v-dialog>
        <div class="redeem-now mb-2">
          <v-btn color="primary" rounded style="font-weight: bold" :loading="isLoading" @click="copyText(selectedItem)">
            Copy Code & Redeem Now
          </v-btn>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import * as AT from "@/store/actionTypes";
import { leadFormMixins } from "../../leadclients/mixins";
import moment from "moment";
import "@/styles/coupon.scss";
export default {
  name: "CouponCard",
  mixins: [leadFormMixins],
  props: {
    requestId: {
      type: String,
      default: () => null
    },
    vendor: {
      type: String,
      default: () => null
    }
  },
  data: () => ({
    couponData: [],
    showCouponDetails: true,
    redemptionSteps: null,
    isCouponsLoading: false,
    selectedItem: null,
    isExpanded: false,
    isLoading: false,
    maxLines: 2,
    isIFrameLoaded: false
  }),
  mounted() {
    let coupons = this.getDataFromStorage("coupons");
    if (coupons) {
      this.couponData = JSON.parse(coupons)[this.requestId] || [];
    }

    if (!this.couponData || this.couponData.length == 0) {
      this.$store
        .dispatch(AT.SET_COUPON_DATA, { vendor: this.vendor, requestId: this.requestId })
        .then((couponData) => {
          this.isCouponsLoading = true;
          this.couponData = couponData.data || [];
          this.setDataToStorage("coupons", JSON.stringify({ [this.requestId]: this.couponData }));
        })
        .catch((error) => {
          console.error("Unable to fetch Coupons", error);
          this.isCouponsLoading = true;
        });
    } else {
      this.isCouponsLoading = true;
    }

    let data = this.getDataFromStorage("redeemedCoupons");
    if (data) {
      let parseData = JSON.parse(data);
      for (let title of parseData) {
        this.couponData = this.couponData.map((obj) => (obj.offer_code === title ? { ...obj, isRedeemed: true } : obj));
      }
    }
  },
  methods: {
    onIframeLoad() {
      this.isIFrameLoaded = true;
    },
    formatDateWithMoment(dateString) {
      const date = moment(dateString);

      return `Valid till ${date.format("Do MMMM YYYY")}`;
    },
    truncatedText(description) {
      if (!this.isExpanded && description.length > 100) {
        const limitedText = description.split(" ").slice(0, 100).join(" ");
        return limitedText + "...";
      }
      return description;
    },
    toggleShowMore() {
      this.isExpanded = !this.isExpanded;
    },
    getPercentage(title) {
      const regex = /\d+%/g;
      const percentages = title.match(regex);
      return percentages;
    },
    handleClose() {
      this.showCouponDetails = false;
      this.isExpanded = false;
    },
    openOfferDetail(selectedItem) {
      this.showCouponDetails = true;
      this.selectedItem = selectedItem;
      this.redemptionSteps = selectedItem.redemption_steps;
    },
    cropCode(code) {
      if (code.length <= 3) {
        return code;
      }
      const lastThree = code.slice(-2);
      const masked = "x".repeat(code.length - 2);
      return masked + lastThree;
    },
    async copyText(coupon) {
      this.isLoading = true;
      if (this.vendor === "jio" && "plan_id" in coupon) {
        let params = "planId=" + coupon.plan_id + "&requestId=" + this.requestId;
        this.$store.dispatch(AT.COUPON_REDEEM, params).then(() => {
          coupon.isRedeemed = true;
          let data = JSON.parse(this.getDataFromStorage("redeemedCoupons")) || [];
          data.push(coupon.offer_code);
          this.setDataToStorage("redeemedCoupons", JSON.stringify(data));

          navigator.clipboard
            .writeText(coupon.offer_code)
            .then(() => {
              location.href = coupon.redemption_url;
            })
            .finally(() => {
              this.isLoading = false;
            });
        });
        this.$store.dispatch(AT.SNACKBAR, {
          color: "success",
          text: "Code Successfully Copied!",
          timeout: 500
        });
        this.isLoading = false;
      }
    },
    adjestLogo() {
      return {
        width: this.vendor == "grabon" ? "28%" : "25%",
        background: "none",
        "box-shadow": "none",
        "margin-left": "50px"
      };
    }
  }
};
</script>
<style scoped>
:deep(.steps-container) {
  font-family: Arial, sans-serif;
  border-radius: 8px;
  border: solid #dcd3d3;
  padding: 6px;
}

:deep(.steps-container b) {
  font-size: 15px;
  text-decoration: underline;
}

:deep(.steps-container) > p {
  font-size: 13px;
  font-weight: 500;
}

:deep(.steps-container) > p strong,
:deep(.steps-container) > p u {
  font-size: 15px;
}

:deep(.steps-container ul) {
  list-style-type: disc;
  padding-left: 20px;
  font-size: 12px;
  font-weight: 500;
  font-family: sans-serif;
}
:deep(.v-snackbar__wrapper) {
  bottom: 40px;
}
:deep(.v-img__img--cover) {
  object-fit: unset !important;
}
</style>
